import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import { PromiseType } from '@/utils/types/PromiseType'
import {
  ChecklistTaskId,
  ChecklistTaskInput,
  ChecklistTaskOutput,
  UseCreateChecklistTask,
  UseDeleteChecklistTask,
  UseGetChecklistTask,
  UseGetChecklistTasks,
  UseUpdateChecklistTask,
} from './types/checklistTask'

const VERSION = 'v1'
const RESOURCE = 'checklist-tasks'

function useGetChecklistTasks(): UseGetChecklistTasks {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetChecklistTasks['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetChecklistTask(): UseGetChecklistTask {
  const axios = useAxios<PromiseType<ReturnType<UseGetChecklistTask['getChecklistTask']>>>({ method: 'GET' })

  function getChecklistTask(id: ChecklistTaskId): Promise<ChecklistTaskOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    getChecklistTask,
  }
}

function useCreateChecklistTask(): UseCreateChecklistTask {
  const axios = useAxios<PromiseType<ReturnType<UseCreateChecklistTask['createChecklistTask']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createChecklistTask(data: ChecklistTaskInput): Promise<ChecklistTaskOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createChecklistTask,
  }
}

function useUpdateChecklistTask(): UseUpdateChecklistTask {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateChecklistTask['updateChecklistTask']>>>({
    method: 'PUT',
  })

  function updateChecklistTask(id: ChecklistTaskId, data: ChecklistTaskOutput): Promise<ChecklistTaskOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateChecklistTask,
  }
}

function useDeleteChecklistTask(): UseDeleteChecklistTask {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteChecklistTask['deleteChecklistTask']>>>({
    method: 'DELETE',
  })

  function deleteChecklistTask(id: ChecklistTaskId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteChecklistTask,
  }
}

export {
  useGetChecklistTasks,
  useGetChecklistTask,
  useCreateChecklistTask,
  useUpdateChecklistTask,
  useDeleteChecklistTask,
}
