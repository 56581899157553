
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { mdiDelete, mdiPlus } from '@mdi/js'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'

import { isRequired } from '@/utils/validation'
import { handleError } from '@/utils/handleError'
import { FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { isOfType } from '@/utils/types/isOfType'

import { ChecklistTemplateInput, ChecklistTemplateOutput } from '@/api/types/checklistTemplate'
import { useCreateChecklistTemplate, useUpdateChecklistTemplate } from '@/api/checklistTemplate'
import { ChecklistTaskId, ChecklistTaskInput, ChecklistTaskOutput } from '@/api/types/checklistTask'
import { useCreateChecklistTask, useDeleteChecklistTask, useUpdateChecklistTask } from '@/api/checklistTask'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'

export default defineComponent({
  name: 'AddEditChecklistTemplateDialog',
  components: {
    CommonAddEditDialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    checklistTemplateToEdit: {
      type: Object as PropType<ChecklistTemplateOutput>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const isEditMode = computed(() => Boolean(props.checklistTemplateToEdit))

    const FORM_FIELDS: FormField[] = [
      {
        value: 'title',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('baseData.checklistTemplates.form.title') as string)],
      },
      {
        value: 'description',
        fieldType: FORM_FIELDS_ENUM.TEXTAREA,
        isRequired: false,
        rules: [],
      },
    ]

    const form = ref<ChecklistTemplateOutput | ChecklistTemplateInput>(
      isEditMode.value ? cloneDeep(props.checklistTemplateToEdit) : ({} as ChecklistTemplateInput)
    )

    const { createChecklistTemplate, isLoading: isLoadingCreateChecklistTemplate } = useCreateChecklistTemplate()
    const { createChecklistTask } = useCreateChecklistTask()

    async function onAdd(): Promise<void> {
      if (!isOfType<ChecklistTemplateOutput>(form.value, 'id')) {
        try {
          const resChecklistTemplate = await createChecklistTemplate({ ...form.value })

          const newChecklistTaskSetPromises = newChecklistTaskSet.value.map((row) => {
            row.checklistTemplate = resChecklistTemplate.id
            return createChecklistTask(row)
          })

          await Promise.all(newChecklistTaskSetPromises)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateChecklistTemplate, isLoading: isLoadingUpdateChecklistTemplate } = useUpdateChecklistTemplate()

    const { deleteChecklistTask } = useDeleteChecklistTask()
    const { updateChecklistTask } = useUpdateChecklistTask()

    async function onEdit(): Promise<void> {
      if (isOfType<ChecklistTemplateOutput>(form.value, 'id')) {
        try {
          const resChecklistTemplate = await updateChecklistTemplate(form.value.id, form.value)

          const checklistTaskSetToEditDelete = cloneDeep(checklistTaskSet.value)

          // Update
          const checklistTaskSetToUpdatePromises = form.value.checklistTaskSet
            .filter((row) => !props.checklistTemplateToEdit.checklistTaskSet.some((row2) => row2.title === row.title))
            .map((row) => {
              const checklistTaskToUpdate = mapBasicEntityToIdWithForm(row)
              return updateChecklistTask(row.id, checklistTaskToUpdate)
            })

          await Promise.all(checklistTaskSetToUpdatePromises)

          //Delete
          const checklistTaskSetToDeletePromises = form.value.checklistTaskSet
            .filter((row) => !checklistTaskSetToEditDelete.some((row2) => row2.id === row.id))
            .map((row) => {
              return deleteChecklistTask(row.id)
            })

          await Promise.all(checklistTaskSetToDeletePromises)

          //Create
          const newChecklistTaskSetPromises = newChecklistTaskSet.value.map((row) => {
            row.checklistTemplate = resChecklistTemplate.id
            createChecklistTask(row)
          })

          await Promise.all(newChecklistTaskSetPromises)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditChecklistTemplate = computed(
      () => isLoadingCreateChecklistTemplate.value || isLoadingUpdateChecklistTemplate.value
    )

    watch(
      () => props.checklistTemplateToEdit,
      () => {
        if (props.checklistTemplateToEdit) {
          form.value = cloneDeep(props.checklistTemplateToEdit)
        }
      },
      {
        immediate: true,
      }
    )

    function close(): void {
      form.value = {} as ChecklistTemplateOutput

      newChecklistTaskSet.value = []

      emit('reload')

      emit('close')
    }

    const checklistTaskSet = ref<ChecklistTaskOutput[]>(
      isOfType<ChecklistTemplateOutput>(form.value, 'id') && form.value.checklistTaskSet.length
        ? form.value.checklistTaskSet
        : []
    )

    const newChecklistTaskSet = ref<ChecklistTaskInput[]>([])

    function onAddNewChecklistTaskSet() {
      newChecklistTaskSet.value.push({} as ChecklistTaskInput)
    }

    function onDeleteNewChecklistTaskSet(row: number) {
      newChecklistTaskSet.value.splice(row - 1, 1)
    }

    function onDeleteChecklistTaskSet(checklistTaskId: ChecklistTaskId) {
      checklistTaskSet.value = checklistTaskSet.value.filter((row) => row.id !== checklistTaskId)
    }

    function containsDuplicates(array: string[]): boolean {
      if (array.length !== new Set(array).size) {
        return true
      }
      return false
    }

    function checkForDuplicates(taskName: string) {
      if (
        checklistTaskSet.value.map((task) => task.title).includes(taskName) ||
        containsDuplicates(newChecklistTaskSet.value.map((task) => task.title))
      ) {
        return root.$t('baseData.checklistTemplates.checklistTaskSet.form.checklistTaskDuplicate')
      } else return true
    }

    return reactive({
      icons: {
        mdiPlus,
        mdiDelete,
      },
      constants: {
        FORM_FIELDS_ENUM,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        checklistTaskSet,
        newChecklistTaskSet,

        isLoadingAddEditChecklistTemplate,
      },
      listeners: {
        onAdd,

        onEdit,

        onAddNewChecklistTaskSet,

        onDeleteNewChecklistTaskSet,
        onDeleteChecklistTaskSet,
      },
      functions: {
        formRules: {
          isRequired,
          checkForDuplicates,
        },
      },
    })
  },
})
