var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonAddEditDialog', {
    attrs: {
      "value": _vm.value,
      "is-edit-mode": _vm.state.isEditMode,
      "is-loading": _vm.state.isLoadingAddEditChecklistTemplate
    },
    on: {
      "add": function add($event) {
        _vm.state.isEditMode ? _vm.listeners.onEdit() : _vm.listeners.onAdd();
      },
      "close": function close($event) {
        return _vm.$emit('close');
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t("baseData.checklistTemplates.form.".concat(_vm.state.isEditMode ? 'edit' : 'add', ".title"))) + " ")];
      },
      proxy: true
    }, {
      key: "fields",
      fn: function fn() {
        return [_vm._l(_vm.constants.FORM_FIELDS, function (entry) {
          return _c('div', {
            key: entry.value
          }, [entry.fieldType === _vm.constants.FORM_FIELDS_ENUM.TEXT ? _c('v-text-field', {
            class: entry.isRequired && 'required',
            attrs: {
              "label": _vm.$t("baseData.checklistTemplates.form.".concat(entry.value)),
              "rules": [].concat(entry.rules)
            },
            model: {
              value: _vm.state.form[entry.value],
              callback: function callback($$v) {
                _vm.$set(_vm.state.form, entry.value, $$v);
              },
              expression: "state.form[entry.value]"
            }
          }) : _vm._e(), entry.fieldType === _vm.constants.FORM_FIELDS_ENUM.TEXTAREA ? _c('v-textarea', {
            class: entry.isRequired && 'required',
            attrs: {
              "label": _vm.$t("baseData.checklistTemplates.form.".concat(entry.value)),
              "rules": [].concat(entry.rules),
              "auto-grow": "",
              "rows": "1"
            },
            model: {
              value: _vm.state.form[entry.value],
              callback: function callback($$v) {
                _vm.$set(_vm.state.form, entry.value, $$v);
              },
              expression: "state.form[entry.value]"
            }
          }) : _vm._e()], 1);
        }), _vm.state.isEditMode ? _c('div', _vm._l(_vm.state.checklistTaskSet, function (row) {
          return _c('div', {
            key: row.id,
            staticClass: "d-flex align-center"
          }, [_c('v-text-field', {
            staticClass: "hint__warning",
            attrs: {
              "label": _vm.$t("baseData.checklistTemplates.checklistTaskSet.form.title"),
              "hint": _vm.$t("baseData.checklistTemplates.checklistTaskSet.form.taskContent")
            },
            model: {
              value: row.title,
              callback: function callback($$v) {
                _vm.$set(row, "title", $$v);
              },
              expression: "row.title"
            }
          }), _c('v-btn', {
            staticClass: "mx-1",
            attrs: {
              "icon": "",
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.listeners.onDeleteChecklistTaskSet(row.id);
              }
            }
          }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiDelete) + " ")])], 1)], 1);
        }), 0) : _vm._e(), _vm._l(_vm.state.newChecklistTaskSet.length, function (row) {
          return _c('div', {
            key: row,
            staticClass: "d-flex align-center"
          }, [_c('v-text-field', {
            attrs: {
              "label": _vm.$t("baseData.checklistTemplates.checklistTaskSet.form.title"),
              "rules": [_vm.functions.formRules.checkForDuplicates(_vm.state.newChecklistTaskSet[row - 1].title)]
            },
            model: {
              value: _vm.state.newChecklistTaskSet[row - 1].title,
              callback: function callback($$v) {
                _vm.$set(_vm.state.newChecklistTaskSet[row - 1], "title", $$v);
              },
              expression: "state.newChecklistTaskSet[row - 1].title"
            }
          }), _c('v-btn', {
            staticClass: "mx-1",
            attrs: {
              "icon": "",
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.listeners.onDeleteNewChecklistTaskSet(row);
              }
            }
          }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiDelete) + " ")])], 1)], 1);
        }), _c('v-row', {
          staticClass: "d-flex align-center justify-center mt-1"
        }, [!_vm.state.newChecklistTaskSet.length && !_vm.state.checklistTaskSet.length ? _c('v-col', {
          staticClass: "mr-auto text-body-1"
        }) : _vm._e(), _c('v-col', {
          staticClass: "ml-auto text-center"
        }, [_c('v-tooltip', {
          staticClass: "mx-auto",
          attrs: {
            "top": "",
            "open-delay": "200"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": _vm.listeners.onAddNewChecklistTaskSet
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")])], 1)];
            }
          }])
        }, [_vm._v(" " + _vm._s(_vm.$t('baseData.checklistTemplates.checklistTaskSet.form.addChecklistTasks')) + " ")])], 1), !_vm.state.newChecklistTaskSet.length && !_vm.state.checklistTaskSet.length ? _c('v-col', {
          staticClass: "ml-auto"
        }) : _vm._e()], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }